<template>
  <div class="nav">
    <div></div>
    <div class="nav-area">
      <!-- <router-link to="/">首页</router-link> |
      <router-link to="/about">港车接送</router-link> |
      <router-link to="/about">香港车牌</router-link> |
      <router-link to="/about">香港身份</router-link> |
      <router-link to="/about">关于我们</router-link> -->
      <div v-for="nav in navList" :key="nav.text">
        <div class="nav-text">{{ nav.text }}</div>
      </div>
    </div>
    
  </div>
</template>

<script setup>
const navList = [{
  text: "首页",
  url: "/"
},{
  text: "港车接送",
  url: "/trans"
},{
  text: "香港车牌",
  url: "/plate"
},{
  text: "香港身份",
  url: "/immigrant"
},{
  text: "关于我们",
  url: "/about"
},]

</script>

<style scoped lang="stylus">

.nav
  height 64px
  padding 0 16px
  line-height 64px
  display flex
  justify-content space-between
  position fixed
  left 0
  right 0
  top 0

.nav-area
  display flex
  gap 12px

.nav-text
  cursor pointer
  font-size 16px
  font-weight bold
  &:hover
    color #000
</style>
