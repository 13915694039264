<template>
  <div class="footer">
    <div>
      <span>© 2023 深圳市智达万物物联网有限公司&emsp;|&emsp;</span>
      <a
        class="icptext"
        target="blank"
        href="http://beian.miit.gov.cn"
      >粤ICP备19057831号</a>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'FooterArea',
  props: {
    msg: String
  }
}
</script>

<style scoped lang="stylus">
.footer
  width 100%
  height 64px
  line-height 64px
  text-align center
  background-color #f2f2f2
</style>
