<template>
  <div class="section">
    <div class="part">
      <h1 class="title">高登领途</h1>
      <div class="subtitle">只为您提供优质服务</div>
    </div>
    <div>

    </div>
      <img src="https://gordon-public-1300434839.cos.ap-guangzhou.myqcloud.com/image/hk-light.png" class="bg">
      <!-- <img alt="Vue logo" src="../assets/hk-light.png" class="bg"> -->

    </div>
</template>

<script setup>

</script>

<style scoped lang="stylus">

.section
  width 100%
  height 800px
  display flex
  justify-content space-between

.part
  height 100vh
  width: 50%;
  padding-left 64px

.title
  font-size 86px
  margin-top 192px
  margin-bottom 32px

.subtitle
  font-size 32px

.bg
  max-height 800px
  width 50%
  float: right;
  object-fit: cover;
  object-position: right top;
</style>